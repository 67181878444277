import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, inject, HostListener, ElementRef } from '@angular/core';
import { NavbarComponent } from "../../shared/components/navbar/navbar.component";
import { ActivatedRoute } from '@angular/router';
import { packages } from 'src/app/core/models/packages';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ApiService } from 'src/app/core/services/api.service';
import { Observable, combineLatestWith, take, BehaviorSubject, of, map } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { IndividualsSummaryComponent } from 'src/app/shared/components/diligence/individuals-summary/individuals-summary.component';
import { SummaryComponent } from 'src/app/shared/components/diligence/summary/summary.component';
import { MyAuthService } from 'src/app/core/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';
import { AuthService } from '@auth0/auth0-angular';
import { FooterComponent } from "../../shared/components/footer/footer.component";
import { transformDiligenceData2 } from './transformer2';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { environment } from 'src/environments/environment';
import { AdminInspectService } from 'src/app/core/services/inspect.service';
import { DisclaimerComponent } from 'src/app/shared/components/diligence/disclaimer/disclaimer.component';
import { ReportDataService } from 'src/app/core/services/report-data.service';
import { DirectorDetailsComponent } from 'src/app/shared/components/diligence/director-details/director-details.component';
import { ModifyDiligenceComponent } from 'src/app/shared/components/diligence/modify-diligence/modify-diligence.component';
import { ReportSection, buildSanctionsReportSections, buildDiligeceReportSections } from 'src/app/shared/components/diligence/report-sections';
import { PdfHeaderComponent } from 'src/app/shared/components/diligence/pdf-header/pdf-header.component';

declare let html2pdf: any;

@Component({
  selector: 'app-diligence',
  templateUrl: './diligence.component.html',
  styleUrls: ['./diligence.component.scss'],
  standalone: true,
  providers: [ApiService, DatePipe, MatSnackBar],
  imports: [
    CommonModule,
    DirectorDetailsComponent,
    ModifyDiligenceComponent,
    MatIconModule,
    PdfHeaderComponent,
    ReportFieldDirective,
    DisclaimerComponent,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    NgxJsonViewerModule,
    SummaryComponent,
    NavbarComponent,
    MatTabsModule,
    IndividualsSummaryComponent,
    MatButtonModule,
    FormsModule,
    FooterComponent
  ]
})
export class DiligenceComponent implements OnInit {
  private pdfViewSubject = new BehaviorSubject<boolean>(false);
  private printViewSubject = new BehaviorSubject<boolean>(false);
  pdfView$ = this.pdfViewSubject.asObservable();
  printView$ = this.printViewSubject.asObservable();
  showInspect$: Observable<boolean>;
  sections$: Observable<ReportSection[]> = of([]);
  visibleSections$: Observable<ReportSection[]> = of([]);
  printableSections$: Observable<ReportSection[]> = of([]);
  packages = packages;
  packageName = '';

  inspectService = inject(AdminInspectService);

  constructor(private elementRef: ElementRef, inspectService: AdminInspectService, private reportDataService: ReportDataService) {
    this.showInspect$ = this.inspectService.showInspect$;
  }

  inspectorWidth = 400; // Initial width
  isResizing = false;
  startX: number = 0;

  startResize(event: MouseEvent) {
    this.isResizing = true;
    this.startX = event.clientX;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isResizing) {
      const deltaX = this.startX - event.clientX;
      const newWidth = this.inspectorWidth + deltaX;

      // Constrain the width between min and max values
      this.inspectorWidth = Math.max(200, Math.min(newWidth, window.innerWidth));

      // Update startX for the next move event
      this.startX = event.clientX;
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.isResizing = false;
  }
  route = inject(ActivatedRoute);
  apiService = inject(ApiService);
  datePipe = inject(DatePipe);
  myAuthService = inject(MyAuthService);
  snackBar = inject(MatSnackBar);
  auth = inject(AuthService);
  cdr = inject(ChangeDetectorRef)

  diligence: any;
  error = '';
  transformedData: any;
  originalDiligence: any;
  isAdmin$: Observable<boolean> = this.myAuthService.isAdmin();
  loading = false;
  environment = environment.environment;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.openDiligenceById(params['id']);
    });
  }

  toggleInspect() {
    this.inspectService.toggleInspect();
  }

  mapScoreElement(arr: any, type: string) {
    if (type === 'date') {
      return arr.map((obj: { date: any; }) => this.datePipe.transform(obj.date, 'yyyy-MM')).reverse()
    }
    if (type === 'industryValue') {
      return arr.map((obj: { industryValue: any; }) => obj.industryValue).reverse()
    }
    if (type === 'companyValue') {
      return arr.map((obj: { companyValue: any; }) => Number(obj.companyValue)).reverse()
    }
  }

  updateDiligence() {
    this.apiService.putDiligence({
      phase: Number(this.diligence.phase),
      repScore: Number(this.diligence.repScore),
      repSumm: this.diligence.repSumm,
      sancIssueSummary: this.diligence.sancIssueSummary,
      critIssueSummary: this.diligence.critIssueSummary,
      critIssueDetails: this.diligence.critIssueDetails,
      previousDiligencePhase: this.originalDiligence.phase,
      uuid: this.diligence.uuid
    }).subscribe(
      () => {
        this.snackBar.open('diligence modified succesfully');
        this.openDiligenceById(this.diligence.uuid);
      }
    )
  }


  openDiligenceById(id: string) {
    this.loading = true;
    this.apiService.getDiligenceById(id).pipe(
      take(1),
      combineLatestWith(this.auth.user$, this.isAdmin$, this.showInspect$)
    ).subscribe(
      ([diligence, user, isAdmin, showInspect]) => {
        transformDiligenceData2(diligence.dueDiligence).then(({ resolvedData, transformer }) => {

          console.log('diligence', diligence)
          console.log('resolvedData', resolvedData)
          this.sections$ = this.pdfView$.pipe(
            map((pdfView: boolean) => {
              console.log(resolvedData.report)
              if (resolvedData.tevunaReport.report === 'R5' || resolvedData.tevunaReport.report === 'R4') {
                return buildSanctionsReportSections(resolvedData, pdfView)
              }
              else {
                return buildDiligeceReportSections(resolvedData, pdfView)
              }
            })
          );
          this.visibleSections$ = this.sections$.pipe(
            map(sections => sections.filter(s => s.display || (isAdmin && showInspect)))
          );

          const which_package = packages.find(p => p.code == diligence.dueDiligence.report)

          this.packageName = which_package?.name || '';

          this.printableSections$ = this.sections$.pipe(
            map(sections => sections.filter(s => s.print && (s.display || (isAdmin && showInspect))))
          );
          this.reportDataService.setResolvedData(resolvedData)
          this.transformedData = resolvedData
          this.reportDataService.setTransformer(transformer)
        })
        if ((diligence?.dueDiligence.userEmail === user?.email || isAdmin) && user?.email) {
          this.diligence = diligence.dueDiligence
          this.originalDiligence = { ...diligence.dueDiligence }
        }
        this.loading = false
      },
      error => {
        this.loading = false;
        this.error = error.error.error;
      }
    );
  }

  updatePdfView(newValue: boolean) {
    this.pdfViewSubject.next(newValue);
  }

  updatePrintView(newValue: boolean) {
    this.printViewSubject.next(newValue);
  }


  printPDF(): void {
    this.updatePdfView(true);
    this.updatePrintView(true);
    this.cdr.detectChanges();

    window.print();

    this.updatePdfView(false);
    this.updatePrintView(false);
    this.cdr.detectChanges();
  }

  generatePDF(): void {
    this.updatePdfView(true);
    this.cdr.detectChanges();

    // Wait for the next frame to ensure the view has been updated
    requestAnimationFrame(() => {
      // Additional delay to ensure all async operations have completed
      setTimeout(() => {
        const element = document.getElementById('content-to-print');
        if (!element) {
          console.error('Content to print not found');
          this.updatePdfView(false);
          return;
        }

        // Ensure all PDF sections are loaded
        const pdfSections = element.querySelectorAll('.pdf-section');
        if (pdfSections.length === 0) {
          console.error('PDF sections not found');
          this.updatePdfView(false);
          return;
        }

        const pdfInstance = html2pdf().set({
          margin: [0, 10, 0, 10],
          filename: `Tevuna - ${this.transformedData.tevunaReport.name || 'report'}.pdf`,
          pagebreak: { mode: ['avoid-all'] },
          html2canvas: { scale: 2, letterRendering: true },
          jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' }
        });

        let pdfPromise = pdfInstance.from(element).toPdf();

        pdfPromise.get('pdf')
          .save()
          .then(() => {
            this.updatePdfView(false);
            this.cdr.detectChanges();
          })
          .catch((error: Error) => {
            console.error('Error generating PDF:', error);
            this.updatePdfView(false);
            this.cdr.detectChanges();
          });
      }, 100); // Adjust this delay as needed
    });
  }
}
